.App {
  padding: 50px;
}

.App.reply {
  padding-bottom: 0;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  color: white;
}

.App-header img {
  width: 150px;
  margin-right: 100px;
}

.App-header h1 {
  font-weight: 800;
}

.App p {
  color: white;
}

.App button.btn-yellow,
.App button.btn-red,
.modal-dialog button.btn-yellow,
.done-ctn a.btn-yellow {
  background-color: white;
  border-radius: 25px;
  padding: 5px 15px;
  font-weight: 800;
  font-size: 1.17em;
  display: flex;
  justify-content: center;
}

.App button.btn-yellow,
.modal-dialog button.btn-yellow,
.done-ctn a.btn-yellow {
  border: 3px solid #F8C907;
  min-width: 150px;
  color: black;
}

.App button.btn-yellow.filled,
.modal-dialog button.btn-yellow.filled,
.done-ctn a.btn-yellow.filled {
  background-color: #F8C907;
}

.App button.btn-red {
  border: 3px solid #ED1C24;
  padding: 5px 10px;
}

.App button.btn-yellow.disabled {
  border: 3px solid #B0B3B8;
  background-color: #B0B3B8 !important;
  color: #7C7E81;
}

.App button.btn-yellow.add-btn::after {
  content: 'ADD';
}

.App button.btn-red.delete-btn::after {
  color: #ED1C24;
  content: 'DELETE';
}

.App button.btn-yellow.additional-reference-btn::after {
  content: 'ADD ADDITIONAL REFERENCE';
}

.App > * + * {
  margin-top: 30px;
}

.App > div.employer-reference-confirmation,
.App > div.employee-reference-upload {
  height: 100%;
}

.reply-ctn {
  background-color: white;
  margin: 50px -50px 0 -50px;
  padding: 30px 50px 0 50px;
  display: flex;
  flex-direction: column;
}

.App.reply .reply-ctn {
  min-height: calc(100vh - 152px);
}

.reply-ctn p {
  color: black;
}

.reply-ctn .reply-ctn-body {
  flex: 1 1 auto;
}

.reply-ctn .reply-ctn-body .info-ctn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.reply-ctn .reply-ctn-body .step-2 .info-ctn {
  width: unset;
  margin: 0 -50px;
}

.reply-ctn .reply-ctn-body .submit-ctn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.reply-ctn .reply-ctn-body .info-field {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-items: flex-end;
  padding: 20px;
}

.reply-ctn .reply-ctn-body .step-2 .info-field {
  align-items: flex-start;
  padding-left: 50px;
}

.reply-ctn .reply-ctn-body .info-field:nth-child(even),
.reply-ctn .reply-ctn-body .info-field:last-child {
  padding-right: 50px;
}

.reply-ctn .reply-ctn-body .info-field:last-child {
  flex: 1 1 auto;
}

.reply-ctn .reply-ctn-body .step-1 .info-field:last-child > label {
  white-space: nowrap;
}

.reply-ctn .reply-ctn-body .step-2 .info-field:last-child > label {
  padding-top: 5px;
}

.reply-ctn .reply-ctn-body .info-field label {
  padding-right: 30px;
}

.reply-ctn .reply-ctn-body .step-2 .info-field label {
  width: calc(100% /6);
}

.reply-ctn .reply-ctn-body .info-field > * {
  font-weight: bold;
}

.reply-ctn .reply-ctn-body .info-field > input,
.reply-ctn .reply-ctn-body .info-field #work_length > button {
  border: none;
  width: 350px;
}

.reply-ctn .reply-ctn-body .info-field > textarea {
  width: calc((100%/6) * 5);
  border: none;
  padding: 5px 10px;
  background-color: rgba(219, 219, 219, 0.3);
}

.reply-ctn .reply-ctn-body .info-field > input.errored {
  border-bottom: 2px solid #ED1C24;
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row input[type="radio"].errored,
.reply-ctn .reply-ctn-body .info-field #work_length > button.errored {
  border: 2px solid #ED1C24;
}

.reply-ctn .reply-ctn-body .info-field #work_length > button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(219, 219, 219, 0.3);
  color: black;
}

.reply-ctn .reply-ctn-body .info-field #work_length > div:last-child {
  width: 100%;
}

.reply-ctn .reply-ctn-body .info-field > input {
  border-bottom: 1px solid black;
}

.reply-ctn .reply-ctn-body .ratings-ctn {
  margin: 0 -50px;
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row {
  display: flex;
  align-items: center;
  justify-items: center;
  font-weight: bold;
  padding: 10px 50px;
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row:nth-child(odd) {
  background-color: rgba(219, 219, 219, 0.3);
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row .ratings-col {
  width: calc(100% / 6);
  text-align: center;
}

.reply-ctn .reply-ctn-body .step-2 .info-field label,
.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row .ratings-col.title {
  text-align: right;
  padding-right: 30px;
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row input[type="radio"] {
  appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: unset;
  -webkit-appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;

  border: 2px solid #999;
  transition: 0.2s all linear;

  position: relative;
  top: 4px;
}

.reply-ctn .reply-ctn-body .ratings-ctn .ratings-row input[type="radio"]:checked {
  border: 8px solid black;
}

.reply-ctn .reply-ctn-body.completed,
.reply-ctn .reply-ctn-body.completed > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reply-ctn .reply-ctn-body.completed > div:first-child {
  flex: 1 1 auto;
}

.reply-ctn .reply-ctn-body.completed img.check {
  width: 48px;
}

.reply-ctn .reply-ctn-body.completed img.check.hide {
  visibility: hidden;
}

.reply-ctn .reply-ctn-body.completed p {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0px;
}

.reply-ctn .reply-ctn-body.completed button {
  margin: 35px 0 75px 0;
  padding: 5px 30px;
}

.reply-ctn .reply-ctn-body.completed .clients-ctn {
  width: 80%;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
}

.reply-ctn .reply-ctn-body.completed .clients-ctn > p {
  min-width: 100%;
  display: block;
  margin-top: 0;
  margin-bottom: 20px;
  color: #C2C2C2;
}

.reply-ctn .reply-ctn-body.completed .clients-ctn > div {
  min-width: fit-content;
  width: calc(100%/4);
  text-align: center;
}

.reply-ctn .reply-ctn-body.completed .clients-ctn > div > img {
  width: auto;
  margin: 20px 0;
}

.reply-ctn .reply-ctn-footer {
  flex: 0 1 auto;
  padding-top: 20px;
}

.references-ctn,
.done-ctn {
  background-color: #C3C6CB;
  border-radius: 10px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.done-ctn {
  align-items: center;
}

.done-ctn > * {
  padding: 20px 50px;
  margin: unset;
  text-align: center;
}

.done-ctn > p {
  color: black;
}

.done-ctn > a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.done-ctn > a + a {
  margin-top: 20px;
}

.done-ctn > *:first-child {
  margin-top: 50px;
}

.done-ctn > *:last-child {
  margin-bottom: 70px;
}

.reference-row {
  padding: 10px 50px;
  border-bottom: 1px solid #79797A;
}

.reference-row > * {
  margin: 5px 0;
}

.reference-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reference-row .left-column > * + *{
  margin-top: 0.5rem;
}

.reference-row .left-column > h3 {
  font-weight: 800;
  font-size: calc(1.2rem + .4vw);
}

.reference-row .left-column > a,
.reference-row .left-column > a:hover {
  display: block;
  text-decoration: underline !important;
  cursor: pointer;
}

.reference-row .left-column span.edit-link,
.reference-row .left-column span.edit-link:hover{
  color: #0F5A1A;
  cursor: pointer;
  text-decoration: underline;
}

.reference-row.submit {
  justify-content: center;
  padding-bottom: 30px;
  border-bottom: none;
}

/* overwrite bootstrap modal CSS */
.modal-open .modal {
  display: flex !important;
  flex-direction: column;
  height: 100%;
}

.modal-open .modal .modal-dialog {
  margin: auto;
}

.modal.fade {
  transition: none;
}

.modal-dialog {
  min-width: 70%;
}

.modal-dialog .modal-content {
  background-color: #EBEBEB;
  padding: 50px;
}

.modal-dialog .modal-content > * {
  margin-bottom: 15px;
}

.modal-dialog .modal-content > .x-btn {
  border: none;
  background-color: unset;
  width: fit-content;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 35px;
  right: 50px;
}

.modal-dialog .modal-content h4 {
  font-weight: 800;
}

.modal-dialog .modal-content .body-ctn .field-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.modal-dialog .modal-content .body-ctn .field-row > * + * {
  margin-left: 25px;
}

.modal-dialog .modal-content .body-ctn .field-row input[type="text"] {
  width: 50%;
}

.modal-dialog .modal-content .body-ctn .field-row input.errored {
  border: 2px solid #ED1C24;
}

.modal-dialog .modal-content .body-ctn .field-row input[type="checkbox"] {
  height: 39px;
  width: 39px;
}

.modal-dialog .modal-content .body-ctn .field-row input[type="checkbox"].errored {
  outline: 2px solid #ED1C24;
}

.modal-dialog .modal-content .footer-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

/* spinner */
.spinner {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.spinner:after {
  background: #111C2E;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* smaller screens */
@media all and (max-width: 1024px) {
  .reply-ctn .reply-ctn-body .info-field > input,
  .reply-ctn .reply-ctn-body .info-field #work_length > button {
    width: 250px;
  }
}

@media all and (max-width: 992px) {
  .App p,
  .App-header {
    justify-content: center;
    text-align: center;
  }

  .App-header > img {
    margin: 0 0 20px 0;
  }

  .App-header h1.is-reply {
    width: 100%;
  }

  .reply-ctn .reply-ctn-body > * > * {
    justify-content: center;
  }

  .reply-ctn .reply-ctn-body .info-field:last-child > label {
    white-space: normal;
  }

  .reply-ctn .reply-ctn-body .info-field:nth-child(even),
  .reply-ctn .reply-ctn-body .info-field:last-child {
    padding-right: 20px;
  }

  .reply-ctn .reply-ctn-footer {
    align-self: center;
  }
}

@media all and (max-width: 768px) {
  .App {
    padding: 50px 15px 15px 15px;
  }

  .reply-ctn {
    margin: 15px -15px 0 -15px;
    padding: 15px 15px 0 15px;
  }

  .reply-ctn .reply-ctn-body .ratings-ctn {
    margin: 0 -15px;
    border: 1px solid black;
    border-left: none;
    border-right: none;
  }

  .reply-ctn .reply-ctn-body .step-2 .info-ctn {
    margin: 0 -15px;
  }

  .reply-ctn .reply-ctn-body .submit-ctn {
    margin-top: 20px;
  }

  .references-ctn,
  .done-ctn {
    min-width: 350px;
  }

  .done-ctn > * {
    padding: 10px 30px;
    margin: unset;
    text-align: center;
  }

  .App button.btn-yellow,
  .modal-dialog button.btn-yellow,
  .done-ctn a.btn-yellow {
    padding: 5px 12px;
  }

  .App button.btn-yellow,
  .modal-dialog button.btn-yellow,
  .done-ctn a.btn-yellow {
    min-width: unset;
  }
  
  .App button.btn-yellow.additional-reference-btn {
    padding: 5px 10px;
  }

  .App button.btn-yellow.add-btn::after {
    content: '↑';
  }

  .App button.btn-red.delete-btn::after {
    content: '❌';
  }

  .App button.btn-yellow.additional-reference-btn::after {
    content: '＋';
  }

  .reference-row {
    padding: 10px 20px;
  }

  .reference-row > *:last-child {
    margin-left: 5px;
  }

  .modal-dialog .modal-content {
    padding: 25px;
  }
}

@media all and (max-width: 425px) {
  .reply-ctn {
    height: 100%;
  }
}